export const usePageContent = () => {
  const { currentLocale, defaultLocale } = storeToRefs(useGlobalStore());
  const { getLocalesContentData } = useProjectMethods();

  const isLoading = ref(false);

  const getPageContent = async <T>(
    contentKey: string,
    dataKeys: string[],
    findMethod: 'find' | 'findOne'
  ): Promise<Ref<Maybe<T>>> => {

    const fetchContent = async (): Promise<T> => {
      const nuxtContentData = useNuxtData(contentKey);
      if (nuxtContentData.data.value) return nuxtContentData.data.value;

      const [currentLocaleContentResponse, defaultLocaleContentResponse] = await Promise.allSettled([
        queryContent(currentLocale.value?.code as string, ...dataKeys)[findMethod](),
        currentLocale.value?.isDefault
          ? Promise.reject('Current locale is default locale!')
          : queryContent(defaultLocale.value?.code as string, ...dataKeys)[findMethod](),
      ]);

      return getLocalesContentData(currentLocaleContentResponse, defaultLocaleContentResponse) as T;
    };

    const { data } = await useLazyAsyncData(contentKey, async () => {
      try {
        isLoading.value = true;
        return await fetchContent();
      } finally {
        isLoading.value = false;
      }
    });

    return data as Ref<T> || null;
  };

  const getPageContentFindOne = <T>(contentKey: string, dataKeys: string[]) =>
    getPageContent<T>(contentKey, dataKeys, 'findOne');

  const getPageContentFind = <T>(contentKey: string, dataKeys: string[]) =>
    getPageContent<T>(contentKey, dataKeys, 'find');

  return {
    getPageContentFindOne,
    getPageContentFind,
    isLoading,
  };
};
